// Vendor imports
import { Fancybox } from "@fancyapps/ui"
import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from '@alpinejs/focus'
import intersect from "@alpinejs/intersect"
import breakpoint from "alpinejs-breakpoints"
import * as rive from "@rive-app/canvas";
 
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(breakpoint)
Alpine.plugin(collapse)


// Start Alpine.js
window.Alpine = Alpine
window.AlpineBreakpointPluginBreakpointsList = [
	"xs",
	"sm",
	"md",
	"lg",
	"xl",
	"2xl",
	"3xl",
]
window.Alpine.start()
import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes"
import "lazysizes/plugins/object-fit/ls.object-fit"

// Module imports
import "./modules/svgIconSprite"
import "./modules/fancyapps"
import "./modules/header"

import "./modules/carousel"
import "./modules/animation"


// Rive Animation
let riveInstances = [];
const rives = document.querySelectorAll('.rive_canvas');

function loadRiveFile(src, onSuccess, onError) {
	const file = new rive.RiveFile({
		src: src,
		onLoad: () => onSuccess(file),
		onLoadError: onError,
	});
	// Remember to call init() to trigger the load;
	file.init().catch(onError);
}

function setupRiveInstance(loadedRiveFile, canvastarget) {
	const canvas = canvastarget;
	if (!canvas) return;

	const riveInstance = new rive.Rive({
		riveFile: loadedRiveFile,
		artboard:"explainer",
		canvas: canvas,
		layout: new rive.Layout({
			fit: rive.Fit.Contain,
			layoutScaleFactor:3,
			alignment: rive.Alignment.BottomCenter
		}),
		autoplay: true,
		onLoad: () => {
		  riveInstance.resizeDrawingSurfaceToCanvas();
		},
	});

	riveInstances.push(riveInstance);
}

rives.forEach((el) =>{
	if(!el){
      return;
	}
	const riveurl = el.dataset.riveurl;
	if(riveurl){
		// Loads the .riv file and initializes multiple Rive instances using the same loaded RiveFile in memory
		loadRiveFile(
			riveurl,
			(file) => {
				setupRiveInstance(file,el);
			},
			(error) => {
				console.error("Failed to load Rive file:", error);
			}
		);
			
	
	}
});

// Resize the drawing surface for all instances if the window resizes
window.addEventListener("resize",
() => {
	riveInstances.forEach((instance) => {
	if (instance) {
		instance.resizeDrawingSurfaceToCanvas();
	}
	});
},
false
);







// Custom Offsets For table

const tablesContentbtns = document.querySelectorAll('.tablebtn');
tablesContentbtns.forEach(item=>{
	if(!item){
	return
	}
	item.addEventListener('click', (e) =>{
		e.preventDefault();
		const targethref = e.target.dataset.eltarget;
		const targetEL = document.querySelector(`${targethref}`);
		if(targetEL){
			const elementPosition = targetEL?.getBoundingClientRect().top + window.scrollY;
			const offsetPosition = elementPosition - 150;
			window.scrollTo({ top: offsetPosition, behavior: "smooth" });
		}
	});
})