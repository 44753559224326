import { animate, inView, stagger, delay } from "motion"

// const splitText = (textContainer) => {
// 	// const textContainer = document.querySelector(selector)
// 	if (!textContainer) {
// 		console.error("Element not found")
// 		return
// 	}
// 	const originalText = textContainer.textContent.trim();
// 	textContainer.textContent = ""
//   console.log(	originalText.trim().split(" "))
// 	originalText.trim().split(" ").forEach((it) => {

// 		const charElement = document.createElement("span");
    
// 		charElement.classList.add("animate_title__span")
// 		textContainer.appendChild(charElement)
// 		charElement.innerHTML = `&nbsp;`;

// 		it.split("").forEach((char) => {
// 			const charElement = document.createElement("span")
// 			charElement.classList.add("animate_title__span")
// 			charElement.textContent = char
// 			textContainer.appendChild(charElement)
// 		})
// 	})
// }

const splitText = (textContainer) => {
  if (!textContainer) {
    console.error("Element not found")
    return
  }
  const originalText = textContainer.textContent.trim();
  textContainer.textContent = ""
  originalText.trim().split(" ").forEach((word, index) => {
    word.split(" ").forEach((char) => {
      const charElement = document.createElement("span")
      charElement.classList.add("animate_title__span")
      charElement.textContent = char
      textContainer.appendChild(charElement)
    })
    if (index < originalText.trim().split(" ").length - 1) {
      const spaceElement = document.createElement("span")
      spaceElement.classList.add("animate_title__span")
      spaceElement.innerHTML = "&nbsp;" // or `&nbsp;` if you prefer
      textContainer.appendChild(spaceElement)
    }
  })
}
document.addEventListener('DOMContentLoaded', () => {

  const elements = document.querySelectorAll('.split-text');

  elements.forEach(element => {
    splitText(element);
    // Get all animated characters
    const chars = element.querySelectorAll('.animate_title__span');

    // Set initial state
    chars.forEach(char => {
      char.style.opacity = 0;
      char.style.transform = 'translateY(20px)';
    });

    // Animate when in view
    inView(element, () => {
      animate(
        chars,
        {
          opacity: 1,
          y: 0
        },
        {
          duration: 0.5,
          easing: 'ease-out',
          delay: stagger(0.02)
        }
      );
      return () => {};
    }, {
      amount: 0.5
    });
  });
});